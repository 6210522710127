<template>
  <div>
    <!-- Service section -->
    <div class="row mb-5">
      <div class="col-12">
        <p class="" :style="titleStyles">Top treatment for you</p>
      </div>
        <div
                            
                            class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                            v-for="(product, index) in getServices"
                            :key="index"
                            
                          >
                            <!-- style="max-width: 211px" -->
                            <div
                              class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
                              :style="
                                getCardBackground(ProductData.style) +
                                getCardBorderCSS(ProductData.style) +
                                getPagePadding(ProductData.style) +
                                getFontFamily(ProductData.style) +
                                ListProductMaxWidth(
                                  isDesktopView
                                    ? ProductData.productsPerPage
                                    : ProductData.productsPerPageMobile
                                )
                              "
                            >
                              

                              <div>
                                <div v-if="getProductInfoDetail(ProductData.useProductVariant, product,'image')" style="height: 100%">
                                  <div
                                    v-if="
                                      checkFileType(getProductInfoDetail(ProductData.useProductVariant, product,'image')) == 'video'
                                    "
                                    :style="ListImageAspectRatio(ProductData.style)"
                                  >
                                    <video
                                      ref="videoRef"
                                      :src="`${getProductInfoDetail(ProductData.useProductVariant, product,'image')}`"
                                      :style="
                                        getImageFit(ProductData.style) +
                                        getImageTopBorderCSs(ProductData.style)
                                      "
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      controls
                                    ></video>
                                  </div>
                                  <div
                                    v-else
                                    :style="ListImageAspectRatio(ProductData.style)"
                                  >
                                    <LazyImage
                                      v-if="getProductInfoDetail(ProductData.useProductVariant, product,'image').startsWith('upload')"
                                      :src="'/' + getProductInfoDetail(ProductData.useProductVariant, product,'image')"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      :style="
                                        getImageFit(ProductData.style) +
                                        getImageTopBorderCSs(ProductData.style)
                                      "
                                    />
                                    <LazyImage
                                      v-else
                                      :src="getProductInfoDetail(ProductData.useProductVariant, product,'image')"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      :style="
                                        getImageFit(ProductData.style) +
                                        getImageTopBorderCSs(ProductData.style)
                                      "
                                    />
                                  </div>
                                </div>
                                <LazyImage
                                  v-else
                                  :src="'/images/image_default_placeholder.png'"
                                  alt=""
                                  :style="getImageTopBorderCSs(ProductData.style)"
                                  style="height: 100%; width: 100%"
                                />
                              </div>
                              <div
                                class="mt-2 flex-grow-1"
                                :style="getPaddingCss(ProductData.style)"
                              >
                                <div class="d-flex flex-column h-100">
                                  <!-- Product Title -->
                                  <div class="px-3">
                                    <p :style="getTitleStyles(ProductData.style)">
                                      {{ getProductInfoDetail(ProductData.useProductVariant, product,'title') }}
                                    </p>

                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.rating && isJudgeMeInstalled
                                      "
                                      class="Review px-3"
                                      v-html="product.rating"
                                      :style="getPriceColor(ProductData.style)"
                                    ></div>
                                    <!-- Rating Div end -->

                                    <div
                                      class="d-flex align-items-center"
                                      :style="
                                        'justify-content:' +
                                        ProductData.style.textAlign +
                                        ';'
                                      "
                                    >
                                      <p
                                        v-if="product.price && !isHidePrice"
                                        :style="getPriceColor(ProductData.style)"
                                        class="mr-2"
                                      >
                                        <span
                                          v-if="
                                            CurrencySign &&
                                            currencyPos == 'left'
                                          "
                                          :class="currencySpace ? 'mr-1' : null"
                                          >{{ CurrencySign }}</span
                                        >{{ getProductInfoDetail(ProductData.useProductVariant, product,'price')
                                        }}<span
                                          v-if="
                                            CurrencySign &&
                                            currencyPos == 'right'
                                          "
                                          :class="currencySpace ? 'ml-1' : null"
                                          >{{ CurrencySign }}</span
                                        >
                                      </p>
                                      <p
                                        v-if="
                                          product.compare_at_price &&
                                          product.price !==
                                            product.compare_at_price &&
                                          !isHidePrice
                                        "
                                        :style="getPriceColor(ProductData.style)"
                                      >
                                        <s>
                                          <span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'left'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >{{ product.compare_at_price
                                          }}<span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'right'
                                            "
                                            :class="
                                              currencySpace ? 'ml-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >
                                        </s>
                                      </p>
                                    </div>
                                  </div>
                                  <!-- Product Price , features & buy button -->
                                  <div
                                    class="d-flex flex-column justify-content-between flex-grow-1"
                                  >
                                    <div class="">
                                      <div
                                        v-if="
                                          !ProductData.hideDescription &&
                                          !ProductData.showHeirarchyDesciption
                                        "
                                      >
                                        <div
                                          class="d-flex align-items-center mt-1"
                                          :style="
                                            limitProductDescription(
                                              ProductData.limitDescription,
                                              product.readMore
                                            )
                                          "
                                        >
                                                <div v-if="ProductData.useBulletDescription && product.bullet_description
 && product.bullet_description.length
">

   <div
                                          v-for="(
                                            bulletPoint, index2
                                          ) in product.bullet_description"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-start mt-1"
                                            :style="getBulletStyles(ProductData.style)"
                                          >
                                            <div class="mr-3">
                                             <i
                                                  class="fa "
                                                  :class="ProductData.selectedBulletIcon"
                                                ></i>
                                            </div>
                                            <span style="text-align:start;">{{ bulletPoint }}</span>
                                          </div>
                                        </div>

</div>
                                          <div class="w-100 "  v-else v-html="product.description"></div>
                                          <!-- <div class="w-100 "  v-if="!ProductData.hideDescription" v-html="product.description"></div> -->
                                        </div>
                                        <div
                                          class=""
                                          v-if="
                                            ProductData.limitDescription &&
                                            product.description &&
                                            product.description
                                              .split(' ')
                                              .join('').length > 150
                                          "
                                        >
                                          <button
                                            class="btn"
                                            :class="
                                              product.readMore ? 'mt-2' : 'mt-2'
                                            "
                                            :style="getPriceColor(ProductData.style)"
                                            @click="
                                              product.readMore =
                                                !product.readMore
                                            "
                                          >
                                            <span v-if="product.readMore">{{
                                              translateText("Less info")
                                            }}</span>
                                            <span v-else
                                              >{{ translateText("More info") }}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="mt-3"
                                      v-if="product.detail_link"
                                    >

                                    <!-- <div
                                        class="pb-2 "
                                        v-if="ProductData.useProductVariant && product && product.variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="product" />
                                      </div> -->
                                    
                                      <button
                                     
                                        :style="getProductButtonCss(ProductData.style)"
                                        @click="
                                          goToURLClicked(product.detail_link)
                                        "
                                      >
                                        Book Now
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
       
      </div>

      <!-- Products Section -->
    <div class="row mb-5">
      <div class="col-12">
        <p class="" :style="titleStyles">Top products for you</p>
      </div>
        <div
                            
                            class="col-12 col-sm-6 col-md-6 col-lg-4 mb-3"
                            v-for="(product, index) in getProducts"
                            :key="index"
                            
                          >
                            <!-- style="max-width: 211px" -->
                            <div
                              class="card shadow-sm d-flex flex-column h-100 mx-auto position-relative"
                              :style="
                                getCardBackground(ProductData.style) +
                                getCardBorderCSS(ProductData.style) +
                                getPagePadding(ProductData.style) +
                                getFontFamily(ProductData.style) +
                                ListProductMaxWidth(
                                  isDesktopView
                                    ? ProductData.productsPerPage
                                    : ProductData.productsPerPageMobile
                                )
                              "
                            >
                              <!-- <div
                                style="
                                  position: absolute;
                                  top: -2%;
                                  left: 50%;
                                  transform: translateX(-50%);
                                  z-index: 100;
                                "
                                class="d-flex justify-content-center"
                                v-if="
                                  ProductData.showProductRank &&
                                  index <= products.length - 1
                                "
                              >
                                <p
                                  style="text-align: center"
                                  :style="getProductRankCss(ProductData.style)"
                                  v-if="ProductData.productRankOption[index + 1]"
                                >
                                  <span>{{
                                    ProductData.productRankOption[index + 1].text
                                  }}</span>
                                </p>
                              </div> -->

                              <div>
                                <div v-if="getProductInfoDetail(ProductData.useProductVariant, product,'image')" style="height: 100%">
                                  <div
                                    v-if="
                                      checkFileType(getProductInfoDetail(ProductData.useProductVariant, product,'image')) == 'video'
                                    "
                                    :style="ListImageAspectRatio(ProductData.style)"
                                  >
                                    <video
                                      ref="videoRef"
                                      :src="`${getProductInfoDetail(ProductData.useProductVariant, product,'image')}`"
                                      :style="
                                        getImageFit(ProductData.style) +
                                        getImageTopBorderCSs(ProductData.style)
                                      "
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      controls
                                    ></video>
                                  </div>
                                  <div
                                    v-else
                                    :style="ListImageAspectRatio(ProductData.style)"
                                  >
                                    <LazyImage
                                      v-if="getProductInfoDetail(ProductData.useProductVariant, product,'image').startsWith('upload')"
                                      :src="'/' + getProductInfoDetail(ProductData.useProductVariant, product,'image')"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      :style="
                                        getImageFit(ProductData.style) +
                                        getImageTopBorderCSs(ProductData.style)
                                      "
                                    />
                                    <LazyImage
                                      v-else
                                      :src="getProductInfoDetail(ProductData.useProductVariant, product,'image')"
                                      alt=""
                                      style="
                                        position: absolute;
                                        top: 0;
                                        left: 0;
                                        bottom: 0;
                                        right: 0;
                                        height: 100%;
                                        width: 100%;
                                      "
                                      :style="
                                        getImageFit(ProductData.style) +
                                        getImageTopBorderCSs(ProductData.style)
                                      "
                                    />
                                  </div>
                                </div>
                                <LazyImage
                                  v-else
                                  :src="'/images/image_default_placeholder.png'"
                                  alt=""
                                  :style="getImageTopBorderCSs(ProductData.style)"
                                  style="height: 100%; width: 100%"
                                />
                              </div>
                              <div
                                class="mt-2 flex-grow-1"
                                :style="getPaddingCss(ProductData.style)"
                              >
                                <div class="d-flex flex-column h-100">
                                  <!-- Product Title -->
                                  <div class="px-3">
                                    <p :style="getTitleStyles(ProductData.style)">
                                      {{ getProductInfoDetail(ProductData.useProductVariant, product,'title') }}
                                    </p>

                                    <!-- Rating Div start -->
                                    <div
                                      v-if="
                                        product.rating && isJudgeMeInstalled
                                      "
                                      class="Review px-3"
                                      v-html="product.rating"
                                      :style="getPriceColor(ProductData.style)"
                                    ></div>
                                    <!-- Rating Div end -->

                                    <div
                                      class="d-flex align-items-center"
                                      :style="
                                        'justify-content:' +
                                        ProductData.style.textAlign +
                                        ';'
                                      "
                                    >
                                      <p
                                        v-if="product.price && !isHidePrice"
                                        :style="getPriceColor(ProductData.style)"
                                        class="mr-2"
                                      >
                                        <span
                                          v-if="
                                            CurrencySign &&
                                            currencyPos == 'left'
                                          "
                                          :class="currencySpace ? 'mr-1' : null"
                                          >{{ CurrencySign }}</span
                                        >{{ getProductInfoDetail(ProductData.useProductVariant, product,'price')
                                        }}<span
                                          v-if="
                                            CurrencySign &&
                                            currencyPos == 'right'
                                          "
                                          :class="currencySpace ? 'ml-1' : null"
                                          >{{ CurrencySign }}</span
                                        >
                                      </p>
                                      <p
                                        v-if="
                                          product.compare_at_price &&
                                          product.price !==
                                            product.compare_at_price &&
                                          !isHidePrice
                                        "
                                        :style="getPriceColor(ProductData.style)"
                                      >
                                        <s>
                                          <span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'left'
                                            "
                                            :class="
                                              currencySpace ? 'mr-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >{{ product.compare_at_price
                                          }}<span
                                            v-if="
                                              CurrencySign &&
                                              currencyPos == 'right'
                                            "
                                            :class="
                                              currencySpace ? 'ml-1' : null
                                            "
                                            >{{ CurrencySign }}</span
                                          >
                                        </s>
                                      </p>
                                    </div>
                                  </div>
                                  <!-- Product Price , features & buy button -->
                                  <div
                                    class="d-flex flex-column justify-content-between flex-grow-1"
                                  >
                                    <div class="">
                                      <div
                                        v-if="
                                          !ProductData.hideDescription &&
                                          !ProductData.showHeirarchyDesciption
                                        "
                                      >
                                        <div
                                          class="d-flex align-items-center mt-1"
                                          :style="
                                            limitProductDescription(
                                              ProductData.limitDescription,
                                              product.readMore
                                            )
                                          "
                                        >
                                                <div v-if="ProductData.useBulletDescription && product.bullet_description
 && product.bullet_description.length
">

   <div
                                          v-for="(
                                            bulletPoint, index2
                                          ) in product.bullet_description"
                                          :key="index2"
                                        >
                                          <div
                                            class="d-flex align-items-start mt-1"
                                            :style="getBulletStyles(ProductData.style)"
                                          >
                                            <div class="mr-3">
                                             <i
                                                  class="fa "
                                                  :class="ProductData.selectedBulletIcon"
                                                ></i>
                                            </div>
                                            <span style="text-align:start;">{{ bulletPoint }}</span>
                                          </div>
                                        </div>

</div>
                                          <div class="w-100 "  v-else v-html="product.description"></div>
                                          <!-- <div class="w-100 "  v-if="!ProductData.hideDescription" v-html="product.description"></div> -->
                                        </div>
                                        <div
                                          class=""
                                          v-if="
                                            ProductData.limitDescription &&
                                            product.description &&
                                            product.description
                                              .split(' ')
                                              .join('').length > 150
                                          "
                                        >
                                          <button
                                            class="btn"
                                            :class="
                                              product.readMore ? 'mt-2' : 'mt-2'
                                            "
                                            :style="getPriceColor(ProductData.style)"
                                            @click="
                                              product.readMore =
                                                !product.readMore
                                            "
                                          >
                                            <span v-if="product.readMore">{{
                                              translateText("Less info")
                                            }}</span>
                                            <span v-else
                                              >{{ translateText("More info") }}
                                            </span>
                                          </button>
                                        </div>
                                      </div>
                                    </div>

                                    <div
                                      class="mt-3"
                                      v-if="product.detail_link"
                                    >

                                    <!-- <div
                                        class="pb-2 "
                                        v-if="ProductData.useProductVariant && product && product.variants"
                                      >
                                        <VaraintSelectComp :pro="pro" :product="product" />
                                      </div> -->
                                    
                                      <button
                                     
                                        :style="getProductButtonCss(ProductData.style)"
                                        @click="
                                          goToURLClicked(product.detail_link)
                                        "
                                      >
                                        {{ ProductData.buttonText }}
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
       
      </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import cssFunctionMixin from "../../customize/mixin/cssFunction.js";
export default {
    mixins: [cssFunctionMixin],
props:['Products','ProductData',"isDesktopView"],
data(){
  return{
    titleStyles:`
          color: rgb(0, 0, 0);
    text-align: center;
    padding: 10px 0px;
    margin: 0px 0px 24px;
    opacity: 1;
    transform: rotate(0deg);
    line-height: 1;
    font-weight: bold;
    font-style: normal;
    text-decoration: unset;
    font-size: 24px;
    font-family: Poppins;
    `
  }
},
methods:{
      getTitleStyles(property) {
      return (
        `color:${property?.titleColor || "#000"};opacity:${
          property?.titleTextOpacity || 100
        }%;` +
        " font-weight:" +
        property.fontWeight +
        "; font-style:" +
        property.fontStyle +
        ";text-decoration:" +
        property.textDecoration +
        ";font-size:" +
        property.fontSize +
        "px;font-family:" +
        property.fontFamily +
        "; text-align:" +
        property.textAlign +
        ";"
      );
    },
    getFontFamily(property) {
      return "font-family:" + property.fontFamily + ";";
    },
    getCardBackground(property) {
      return "background-color:" + property.backgroundColor + ";";
    },
    getPriceColor(property) {
      return (
        "color:" +
        property.priceColor +
        ";text-align:" +
        property.textAlign +
        ";"
      );
    },
    getCardBorderCSS(property) {
      return (
        " border-radius:" +
        property.borderRadius +
        "px; border:" +
        property.borderSize +
        "px solid " +
        property.borderColor +
        ";"
      );
    },
    getPagePadding(property, isDesktopView) {
  const getPaddingValue = (desktopValue, mobileValue) =>
    isDesktopView || mobileValue === undefined ? desktopValue : mobileValue;

  const paddingTop = getPaddingValue(property.pagePaddingTop, property.pageMobilePaddingTop);
  const paddingRight = getPaddingValue(property.pagePaddingRight, property.pageMobilePaddingRight);
  const paddingBottom = getPaddingValue(property.pagePaddingBottom, property.pageMobilePaddingBottom);
  const paddingLeft = getPaddingValue(property.pagePaddingLeft, property.pageMobilePaddingLeft);

  return `; text-align: ${property.textAlign}; padding: ${paddingTop}px ${paddingRight}px ${paddingBottom}px ${paddingLeft}px; `;
},
    getImageTopBorderCSs(property) {
      return (
        " border-top-left-radius:" +
        property.borderRadius +
        "px; border-top-right-radius:" +
        property.borderRadius +
        "px ; "
      );
    },
    getImageRatio(property) {
      let imageRatioCSS;
      if (property.imageRatio === "square") {
        imageRatioCSS = "";
      } else if (property.imageRatio === "potrait") {
        imageRatioCSS = `position:absolute;height:100%; width:66%; margin:auto; left:0; right:0;`;
      } else {
        imageRatioCSS = `position:absolute; width:100%; height:66%; margin:auto; top:0; bottom:0;`;
      }

      return " width: 100% " + ";" + "height: 150px" + ";" + imageRatioCSS;
    },
    getImageFit(property) {
      let imageFitCSS;
      if (property.imageFit === "Fit") {
        imageFitCSS = "object-fit: contain" + ";";
      } else if (property.imageFit === "Fill") {
        imageFitCSS = "object-fit: fill" + ";";
      }
      return imageFitCSS;
    },
 goToURLClicked(url) {
      let URL = url;
      let pattern = /^((http|https|ftp):\/\/)/;
      if (URL) {
        if (!pattern.test(URL)) {
          URL = "http://" + URL;
        }
        window.open(URL);
      }
    },
     getProductInfoDetail(isProductVarient,product,value){

        if (
          isProductVarient
        
      ) { 
        return  (product.selectedVariant && product.selectedVariant != '') ?  (product.selectedVariant[value] ? product.selectedVariant[value] : product[value] )    : product[value]
        
      } else {
        return product[value];
      }
    },
     checkFileType(file) {
      let fileType;
      let allowedImageExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
      let allowedVideoExtensions = /(\.mp4)$/i;

      if (!allowedImageExtensions.exec(file)) {
        if (allowedVideoExtensions.exec(file)) {
          fileType = "video";
        } else {
          fileType = "unknown";
        }
      } else {
        fileType = "image";
      }

      return fileType;
    },
},

computed:{
  isHidePrice() {
      return this.getPreviewSettings.hidePrice;
    },
   CurrencySign() {
      return this.getPreviewSettings.currencySign.sign;
    },
   ...mapGetters([
      "translateText",
      "getPreviewSettings"
    ]),
    currencyPos() {
     
        if (this.ProductData.style.Currencyposition) {
          return this.ProductData.style.Currencyposition;
        }
        return "left";
    },
    currencySpace() {
      if (this.CurrencySign == "RON") return true;
      return false;
    },
  getServices(){
    let products = [...this.Products]
    let filteredServices = products.filter((product)=> product.tags.includes('service'))
    return filteredServices.length > 0 ? filteredServices.slice(0,3) : []
    
  },
  getProducts(){
     let products = [...this.Products]
    let filteredProducts = products.filter((product)=> !product.tags.includes('service'))
    // return filteredProducts.length > 0 ? filteredProducts.slice(0,6) : []
    return filteredProducts
  }
}
}
</script>

<style scoped>
.productContainer {
  border-radius: 4px;
  border: 1px solid rgba(42, 175, 175, 0.3);
  position: relative;
}

.productContainer .SelectedProductStyle {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #000000;
  font-size: 12px;
}


.productContainer .ImgContainer img{
 /* width:100%; */
  max-width: 100%;
    height: auto;
    object-fit: cover;
    aspect-ratio: 2/3;
 /* object-fit: cover; */
  
}

@media (max-width: 767px) {
  .productContainer .ImgContainer{
  max-width:172px;
  
}
}
</style>